import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import * as pkg from "../../package.json";

function Copyright() {
  return (
    <div>
      <Box>
        <Typography variant="body2" color="textSecondary" align="center">
          {"© "}
          <Link color="inherit" href={pkg.repository.url} target="_blank">
            本项目为开源项目
          </Link>
          {" / "}
          <Link color="inherit" href="https://shadiao.app" target="_blank">
            更多沙雕APP
          </Link>
        </Typography>
      </Box>
      {/* <Typography variant="body2" color="textSecondary" align="center">
        {" 2019 - " + new Date().getFullYear()}
      </Typography> */}
    </div>
  );
}

export default Copyright;
